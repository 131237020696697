import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {AngularFireAnalytics} from '@angular/fire/analytics';
import { AuthService } from './common/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Kesef.app';
  constructor(public auth: AngularFireAuth, analytics: AngularFireAnalytics, authService: AuthService) {
  }
}
